@use "~@amzn/awsui-design-tokens/polaris" as awsui;

.user-message {
    background-color: #cae3fa !important;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    overflow-wrap: anywhere;
    white-space: pre-wrap;

    .user-initials {
        font-weight: bold;
        color: #000716;
        background-color: #ffffff;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

.procurement-message {
    background-color: #ffffff !important;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    overflow-wrap: anywhere;
    white-space: pre-wrap;

    .user-initials {
        font-weight: bold;
        color: #000716; // Text color
        background-color: #b5d6f4; // Circle color
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center; // Vertical position of initials inside the circle
        justify-content: center; // Horizontal position of initials inside the circle
    }
}

.other-message {
    background-color: #dfdfdf !important;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    overflow-wrap: anywhere;
    white-space: pre-wrap;

    .user-initials {
        font-weight: bold;
        color: #000716; // Text color
        background-color: #b5d6f4; // Circle color
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center; // Vertical position of initials inside the circle
        justify-content: center; // Horizontal position of initials inside the circle
    }
}